import React, { useEffect, useState } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import SendIcon from "../../assets/reach-out-modal/send-icon";
import WhatsappIcon from "../../assets/reach-out-modal/whatsappIcon";
import PhoneIcon from "../../assets/reach-out-modal/phoneIcon";
import ChatBubbleIcon from "../../assets/reach-out-modal/chatBubbleIcon";
import EmailIcon from "../../assets/reach-out-modal/emailIcon";
import { Col, Row } from "reactstrap";

const ContactUsModal = ({ setModalOpen, modalOpen }) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // // useEffect(() => {
    // //     if (modalOpen) {
    // //         setIsModalOpen(true);
    // //     }else{
    // //         setIsModalOpen(false);
    // //     }
    // // }, [modalOpen]);
    return (
        <div>
            {" "}
            {/* {modalOpen && ( */}
            <div
                // onClick={() => {
                //     setModalOpen(false);
                // }}
                className={`modal fade ${modalOpen ? "show" : ""}`}
                id="staticBackdrop"
                data-bs-backdrop="true"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!modalOpen}
            >
                <div className="modal-dialog scrollable reach-out-modal-container modal-dialog-centered">
                    <div className="modal-content">
                        <div data-bs-dismiss="modal" aria-label="Close" className="modal-close-btn">
                            <ModalCancelIcon />
                        </div>
                        <div className="modal-body ">
                            <p className="reach-out-hd">We’re here and we care.</p>
                            <p className="reach-out-text">
                                Reach out with any questions, concerns, comments or suggestions.
                            </p>
                            <div className="reach-modal-input-wrap">
                                <Row className="reach-text-input-wrap input-gap row-cols-1 row-cols-md-2">
                                    <Col>
                                        <input className="reach-input" type="text" placeholder="First Name" />
                                    </Col>
                                    <Col>
                                        <input className="reach-input" type="text" placeholder="Last Name" />
                                    </Col>
                                </Row>
                                <input className="reach-input" type="email" name="email" placeholder="Email" />
                                <div className="textarea-icon-wrap">
                                    <textarea placeholder="Message" className="reach-out-text-area"></textarea>
                                    <div className="send-icon-wrap">
                                        <SendIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="reach-out-btn-wrap">
                                <a href="https://wa.me/+13477657824" className="reach-out-btn bg-light-blue">
                                    <WhatsappIcon />
                                    Whatsapp
                                </a>
                                <a href="tel:+13477657824" className="reach-out-btn bg-light-blue">
                                    <PhoneIcon />
                                    <ChatBubbleIcon />
                                    347.765.7824
                                </a>
                                <a href="mailto:support@hazmoona.com" className="reach-out-btn bg-light-blue">
                                    <EmailIcon />
                                    support@hazmona.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default ContactUsModal;
