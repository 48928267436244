import React, { useEffect, useState } from "react";
// request
import categoriesRequests from "../../requests/categories";
// custom components
import CustomLoader from "../Loader/customLoader";
import CategoryItem from "./categoryItem";
// styles
import "./styles/categories.css";
import useViewportWidth from "../../hooks/useViewportWidth";
import { Col, Row } from "reactstrap";

const CategoriesList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const width = useViewportWidth();
    useEffect(() => {
        categoriesRequests.getCategories().then((_categories) => {
            setCategories(_categories);
            setIsLoading(false);
        });
    }, []);

    return (
        <div className="categories-container-home">
            {isLoading ? (
                <CustomLoader width={100} height={100} />
            ) : (
                <div className="categories-flex-home">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        {categories.map(
                            (cat, index) => (
                                <div className="categories-card">
                                    <CategoryItem key={cat._id} {...cat} />
                                </div>
                            )
                            // categories.length % 4 == 2 && index === categories.length - 2 && width > 730 && width < 1200 ? (
                            //     <>
                            //         <div key={index + "-"}></div> <CategoryItem key={cat._id} {...cat} />
                            //     </>
                            // ) : categories.length % 3 == 1 && index === categories.length - 1 && width < 730 ? (
                            //     <>
                            //         <div key={index + "-"}></div> <CategoryItem key={cat._id} {...cat} />
                            //     </>
                            // ) : (
                            //     <CategoryItem key={cat._id} {...cat} />
                            // )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoriesList;
