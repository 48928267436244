import React, { useState, useEffect } from "react";
import "./styles/signUp.css";
import authRequests from "../../requests/auth";
import user from "../../requests/user";
import ReCAPTCHA from "react-google-recaptcha";
// import GoogleLogin from '../customers/googleLogin';
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../common/ForgotPassword";

import { useAuth } from "../Account/AuthContext";
import Footer from "../../layouts/footer/Footer";
import axios from "axios";
import { useCart } from "../../context/cartContext";

function AccountSignUp() {
    const { authenticated, setAuthenticated } = useAuth();
    const [forgetPassword, setForgetPassword] = useState(false);
    const { reloadCart, isLoading, addItemToCart, cartItems } = useCart();

    const navigate = useNavigate();
    useEffect(() => {
        if (authenticated) {
            navigate("/account/accountprofile", { replace: true });
        }
    }, [authenticated, navigate]);

    const [isSignUp, setIsSignUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        confirmPassword: "",
        recaptchaToken: "",
        terms: false,
    });

    const responseMessage = async (response) => {
        const token = response.credential;

        try {
            const customerLoginGoogle = await authRequests.customerLoginGoogle(token);
            if (customerLoginGoogle) {
                navigate("/account/OrderHistory", { replace: true });
            }
        } catch (error) {
            console.error("Error in customerLoginGoogle:", error);
        }
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === "confirmPassword" || (name === "password" && formData.confirmPassword)) {
            if (name === "confirmPassword" && value !== formData.password) {
                setError("Passwords do not match");
            } else if (name === "password" && formData.confirmPassword !== value) {
                setError("Passwords do not match");
            } else {
                setError("");
            }
        }
    };

    const handleRecaptcha = (value) => {
        setFormData({
            ...formData,
            recaptchaToken: value,
        });
    };

    const handleTerms = (e) => {
        setFormData({
            ...formData,
            terms: e.target.checked,
        });
    };
    const handleForgetPassword = () => {
        setForgetPassword(!forgetPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        if (!formData.recaptchaToken) {
            setError("Please complete the reCAPTCHA  ");
            return;
        }

        if (!formData.terms && isSignUp) {
            setError("Please accept the Terms of Service and Privacy Policy to proceed.");
            return;
        }

        if (error) {
            return;
        }

        setLoading(true);
        setError("");

        try {
            if (isSignUp) {
                const sessionData = await authRequests.signUp({ formData });
                if (sessionData) {
                    setAuthenticated(true);
                    navigate("/account/accountprofile", { replace: true });
                } else {
                    throw new Error("No session data returned");
                }
            } else {
                const sessionData = await authRequests.customerLogin(formData.email, formData.password);
                if (sessionData) {
                    setAuthenticated(true);
                    navigate("/account/accountprofile", { replace: true });
                } else {
                    throw new Error("No session data returned");
                }
            }
        } catch (error) {
            console.error("Error:", error);
            setError(error.message || "Something went wrong. Please try again.");
        }

        setLoading(false);
    };
    return (
        <>
            <div className="form-container">
                {!forgetPassword && (
                    <div className="button-group">
                        <button
                            type="button"
                            onClick={() => setIsSignUp(true)}
                            className={`signup-button ${isSignUp ? "active" : ""}`}
                        >
                            Sign Up
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsSignUp(false)}
                            className={`login-button ${!isSignUp ? "active" : ""}`}
                        >
                            Login
                        </button>
                    </div>
                )}

                {forgetPassword ? (
                    <ForgotPassword
                        setIsSignUp={setIsSignUp}
                        isSignUp={isSignUp}
                        setForgetPassword={setForgetPassword}
                        forgetPassword={forgetPassword}
                    />
                ) : (
                    <form onSubmit={handleSubmit} className="form">
                        <>
                            <div className="inputs-wrap">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="auth-input-feild"
                                />
                                {isSignUp && (
                                    <>
                                        <div className="names-input-wrap">
                                            <input
                                                type="text"
                                                name="firstname"
                                                placeholder="First Name"
                                                value={formData.firstname}
                                                onChange={handleChange}
                                                required
                                                className="auth-input-feild"
                                            />
                                            <input
                                                type="text"
                                                name="lastname"
                                                placeholder="Last Name"
                                                value={formData.lastname}
                                                onChange={handleChange}
                                                required
                                                className="auth-input-feild"
                                            />
                                        </div>
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            className="auth-input-feild"
                                        />
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            required
                                            className="auth-input-feild"
                                        />
                                    </>
                                )}
                                {!isSignUp && (
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        className="auth-input-feild"
                                    />
                                )}
                            </div>
                            {isSignUp ? (
                                <div className="check-privacy-policey">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        checked={formData.terms || false}
                                        id="iAccept"
                                        onChange={handleTerms}
                                    />
                                    <label for="iAccept">
                                        I accept the{" "}
                                        <span
                                            onClick={() => {
                                                navigate("/terms-conditions");
                                            }}
                                        >
                                            Terms of Service and Privacy Policy
                                        </span>
                                    </label>
                                </div>
                            ) : (
                                <div className="remember-forgot-container">
                                    <label className="checkbox-remember-wrap">
                                        <input type="checkbox" />
                                        <span>Remember me</span>
                                    </label>
                                    <a
                                        onClick={() => {
                                            handleForgetPassword();
                                        }}
                                        className="forgot-link"
                                    >
                                        {forgetPassword ? "Back to login" : "Forgot Password?"}
                                    </a>
                                </div>
                            )}
                            {!forgetPassword && (
                                <div className={`recaptcha-wrap ${!isSignUp ? "login-recaptcha" : ""}`}>
                                    <ReCAPTCHA
                                        sitekey="6LeU1M4pAAAAAP8wDgyrh268wX5qcsyoa9ilfEiL"
                                        onChange={handleRecaptcha}
                                    />
                                </div>
                            )}
                            {error && <p className="error-message text-center mt-3">{error}</p>}
                            <button type="submit" disabled={loading} className="auth-button">
                                {loading ? "Loading..." : isSignUp ? "Sign Up" : "Sign In"}
                            </button>
                        </>
                    </form>
                )}

                {/* <div className="google-message-wrap">
                    <button className="google-button" onClick={() => googleLogin()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30.5" height="30.5" viewBox="0 0 48 48">
                            <path
                                fill="#ffc107"
                                d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917"
                            />
                            <path
                                fill="#ff3d00"
                                d="m6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691"
                            />
                            <path
                                fill="#4caf50"
                                d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44"
                            />
                            <path
                                fill="#1976d2"
                                d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917"
                            />
                        </svg>{" "}
                        Continue With Google{" "}
                    </button>
                </div> */}

                <div className="mt-4 d-flex justify-content-center">
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AccountSignUp;
