import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import XIcon from "./XIcon";

import laptop2Image from "../../assets/images/mockup/laptop2.jpg";
import phoneImage from "../../assets/images/mockup/phone.jpg";
import wallImage from "../../assets/images/mockup/wall.jpg";
import flipPhoneImage from "../../assets/images/mockup/flip_phone.jpg";

import "./styles/preview.css";

const BG_WIDTH = 571;
const BG_HEIGHT = 738;

const mockupSet = [
    { image: wallImage, x: 150, y: 215, zoom: 0.45, id: "wall" },
    { image: flipPhoneImage, x: 220, y: 115, zoom: 0.23, id: "flip" },
    { image: phoneImage, x: 179, y: 190, zoom: 0.36, id: "phone" },
    { image: laptop2Image, x: 200, y: 238, zoom: 0.3, id: "laptop" },
];

/**
 * @param {Object} props
 * @param {fabric.Canvas} props.canvas
 * @param {boolean} props.enabled
 */
const CardPreview = ({ canvas, enabled }) => {
    // console.log("canvas", canvas);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(0);
    const base64Url = canvas.toDataURL({
        format: "jpeg",
    });

    window.canvasPreview = canvas;
    window.preview64 = base64Url;

    const handleShowPreview = () => {
        if (!enabled) {
            return;
        }
        setShowModal(true);
    };

    return (
        <div className="preview-container">
            <div className={`preview-container-btn${enabled ? "" : " disabled"}`} onClick={handleShowPreview}>
                <i className="fa fa-eye" aria-hidden="true" />
                <span>Preview</span>
            </div>

            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                size="lg"
                contentClassName="preview-modal-content"
                className="custom-modal-preview-img"
                centered
            >
                <ModalBody>
                    <div className="text-white">
                        <XIcon className="close-icon" onClick={() => setShowModal(false)} />
                        <div className="columns-preview-wrapper">
                            <div className="col-preview col-preview-1">
                                <MockupImage mockup={mockupSet[selected]} image={base64Url} canvas={canvas} />
                            </div>
                            <div className="col-preview col-preview-2 preview-thumbnails-wrapper">
                                {mockupSet.map((mockup, index) => (
                                    <div key={index} className="preview-thumbnail-box">
                                        <MockupImage
                                            small
                                            mockup={mockup}
                                            image={base64Url}
                                            active={index === selected}
                                            onSelect={() => setSelected(index)}
                                            canvas={canvas}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

const MockupImage = ({ small = false, mockup, image, active = false, onSelect, canvas }) => {
    /** @type {React.MutableRefObject<HTMLCanvasElement>} */
    const canvasRef = useRef();

    const { width, height, zoom } = useMemo(() => {
        const zoom = small ? 0.2364 : 1;

        return { width: BG_WIDTH * zoom, height: BG_HEIGHT * zoom, zoom };
    }, [small]);

    useEffect(() => {
        function loadImage() {
            if (!canvasRef.current) {
                setTimeout(loadImage, 50);
                return;
            }
            const context = canvasRef.current.getContext("2d");
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            const bgImage = new Image();
            bgImage.onload = function () {
                const ratio = bgImage.width / bgImage.height;
                const newHeight = width / ratio;
                context.drawImage(this, 0, (height - newHeight) / 2, width, newHeight);
                const previewImage = new Image();

                previewImage.onload = function () {
                    const ratio = previewImage.width / previewImage.height;
                    const newPreviewImgHeight = previewImage.width / ratio;

                    let newPreviewX = mockup.x * zoom;
                    let newPreviewY = mockup.y * zoom;
                    let newMockupZoom = mockup.zoom;
                    // adjust positions a little bit if width is greater than height
                    if (canvas.width >= canvas.height) {
                        if (mockup.id === "flip") {
                            newPreviewX *= 1.005;
                            newPreviewY *= 1.3;
                            newMockupZoom *= 0.98;
                            // square posters
                            if (canvas.width === canvas.height) {
                                newPreviewY *= 0.9;
                            }
                        }
                        if (mockup.id === "laptop") {
                            newMockupZoom *= 1.4;
                            newPreviewY *= 1.1;
                            newPreviewX *= 0.85;
                            // square posters
                            if (canvas.width === canvas.height) {
                                newMockupZoom *= 0.8;
                                newPreviewX *= 1.1;
                            }
                        }
                    }

                    context.drawImage(
                        this,
                        newPreviewX,
                        newPreviewY,
                        newMockupZoom * width,
                        newPreviewImgHeight * newMockupZoom * zoom
                    );
                };
                previewImage.src = image;
            };
            bgImage.src = mockup.image;
        }
        loadImage();
    }, [width, height, zoom, image, mockup]);

    return (
        <canvas
            ref={(r) => (canvasRef.current = r)}
            width={width}
            height={height}
            className={`canvas-mockup-image${active ? " active" : ""}${small ? " pointer" : ""}`}
            onClick={onSelect}
        />
    );
};

export default CardPreview;
