import React from "react";
import "./style.css";
import TermsConditions from "../../assets/TermsAndConditions/Terms.png";
import Footer from "../../layouts/footer/Footer";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import SendIcon from "../../assets/reach-out-modal/send-icon";
import WhatsappIcon from "../../assets/reach-out-modal/whatsappIcon";
import PhoneIcon from "../../assets/reach-out-modal/phoneIcon";
import ChatBubbleIcon from "../../assets/reach-out-modal/chatBubbleIcon";
import EmailIcon from "../../assets/reach-out-modal/emailIcon";

const TermsAndConditions = () => {
    return (
        <>
            <div className="conditions-container">
                <div className="conditions-sub-container container-width">
                    <div className="conditions-text-wrap">
                        <p className="conditions-hd">Terms and Conditions</p>
                        <div className="conditions-wrap">
                            <p className="conditions-sub-hd">Errors</p>
                            <p className="conditions-text">
                                If you received your invitation with a mistake, and the error was due to an oversight on
                                our part, you will be given a full refund, no questions asked. If you mistakenly entered
                                the wrong information while you placed your order, you can edit it free of charge and
                                change up to three fields. If you have a question or issue with your order, please{" "}
                                <span
                                    style={{ cursor: "pointer" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                >
                                    reach out to us
                                </span>
                            </p>
                        </div>
                        <div className="conditions-wrap">
                            <p className="conditions-sub-hd">Privacy</p>
                            <p className="conditions-text">
                                We respect your privacy. Hazmoona will never share your personal information with anyone
                                and will only store the necessary information in our database.
                            </p>
                        </div>
                        <div className="conditions-wrap">
                            <p className="conditions-sub-hd">Copyright</p>
                            <p className="conditions-text">
                                All the invitation designs on this website are the property of Hazmoona. Plagiarizing or
                                copying anything off our site, without a purchase is both unethical and illegal.
                            </p>
                        </div>
                    </div>
                    <div className="conditions-img-wrap">
                        <img src={TermsConditions} alt="" />
                    </div>
                </div>
            </div>
            {/* <div>
                <div
                    className="modal fade "
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog scrollable reach-out-modal-container">
                        <div className="modal-content">
                            <div data-bs-dismiss="modal" aria-label="Close" className="modal-close-btn">
                                <ModalCancelIcon />
                            </div>
                            <div className="modal-body ">
                                <p className="reach-out-hd">We’re here and we care.</p>
                                <p className="reach-out-text">
                                    Reach out with any questions, concerns, comments or suggestions.
                                </p>
                                <div className="reach-modal-input-wrap">
                                    <div className="reach-text-input-wrap">
                                        <input className="reach-input" type="text" placeholder="First Name" />
                                        <input className="reach-input" type="text" placeholder="Last Name" />
                                    </div>
                                    <input className="reach-input" type="email" name="email" placeholder="Email" />
                                    <div className="textarea-icon-wrap">
                                        <textarea placeholder="Message" className="reach-out-text-area"></textarea>
                                        <div className="send-icon-wrap">
                                            <SendIcon />
                                        </div>
                                    </div>
                                </div>
                                <div className="reach-out-btn-wrap">
                                    <button className="reach-out-btn">
                                        <WhatsappIcon />
                                        Whatsapp
                                    </button>
                                    <button className="reach-out-btn">
                                        <PhoneIcon />
                                        <ChatBubbleIcon />
                                        347.765.7824
                                    </button>
                                    <button className="reach-out-btn">
                                        <EmailIcon />
                                        support@hazmona.com
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    );
};

export default TermsAndConditions;
