import React from "react";
import Portal from "./portal";
// img
import closeIcon from "../../assets/images/icons/close-white-icon.svg";
import warningIcon from "../../assets/images/icons/warning-icon.svg";
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";
import "./style/editPurchaseModal.css";

const EditPurchaseWarningModal = ({ isOpen, handleClose, maxEditCount }) => {
    // const { maxEditCount } = useEditPurchaseHook();
    const preventClose = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    };

    if (!isOpen) return null;

    return (
        <Portal>
            <div className="edit-purchase-modal-wrapper purchase-warning-w" onClick={handleClose}>
                <div className="edit-p-modal-content-wrapper" onClick={preventClose}>
                    <div className="edit-p-modal-content">
                        <img src={warningIcon} alt="warning icon" />
                        <p>
                            After purchasing you <br className="br-popup" /> can only edit 3 fields{" "}
                            <br className="br-popup" /> for the next 48 hours
                        </p>
                        <button onClick={handleClose} className="edit-purchase-modal-wrapper-button  ">
                            Ok
                        </button>
                    </div>

                    <div onClick={handleClose} className="edit-p-modal-close">
                        <img src={closeIcon} alt="close icon" />
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default EditPurchaseWarningModal;
