/**
 *  Account Page Order History
 */
import React, { Component, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import OrderData from "../../api/userOrder";
import Sitebar from "./Sitebar";
import Footer from "../../layouts/footer/Footer";
import orderRequests from "../../requests/orders";
import { isCustomerLogin } from "../../utils/common";
import productRequests from "../../requests/products";
import customerRequest from "../../requests/customer";
import { useCart } from "../../context/cartContext";
import fieldsRequests from "../../requests/fields";
import cartRequests from "../../requests/cart";
// import { fabric } from "fabric";
// import { assingObjectsToCanvas, forceRenderUpdate, setCanvasAtResoution, setCustomFontsWhenLoaded, toggleAllFieldsVisibility } from "../../utils/canvas";
// import { getUrlQuery } from "../../utils/url";
import Ordercarddetails from "../orderdetails/Ordercarddetails.jsx";
import CouponsRequest from "../../requests/Coupons.js";

function OrderHistory() {
    const [modal, setModal] = useState(false);
    const [order, setOrder] = useState(false);
    const [products, setProdcuts] = useState({});
    const [ViewOrder, setViewOrder] = useState("");
    const [customer, setCustomer] = useState(null);

    // console.log(ViewOrder.orderId ,"viewOrder");

    function toggle() {
        setModal(!modal);
    }

    function onViewOrder(data) {
        // console.log(data, "data");

        if (products[data._id] == null) {
            fetchProducts(data);
        }
        setViewOrder(data);

        toggle();
    }
    function onLeaveOrder() {
        setViewOrder(null);

        toggle();
    }
    async function fetchOrders() {
        const customerId = isCustomerLogin();
        // console.log(customerId);
        if (!customerId) return;
        const obj = await orderRequests.getOrderItems({ customerId });

        setOrder(obj.data);
        // console.log(obj.data, "obj.data");
    }
    async function fetchProducts(order) {
        const items = order.items;
        const result = [];

        for (let i = 0; i < items.length; i++) {
            const product = await productRequests.getProduct({ productId: items[i].productId });

            result.push(product);
        }

        setProdcuts({ ...products, [order._id]: result });
    }
    async function fetchProfile() {
        const customerId = localStorage.getItem("customerId");
        if (!customerId) return;

        try {
            const userProfile = await customerRequest.getCustomer({ customerId });
            // console.log(userProfile ,"userProfile");

            if (userProfile) {
                setCustomer(userProfile);
            }
        } catch (error) {
            console.error("Error in userProfile:", error);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchOrders();

        fetchProfile();
    }, []);

    function getTotalPrice(order) {
        // console.log(order, "order");
        let total = 0;
        for (let i = 0; i < order.items.length; i++) {
            total += order.items[i].price;
        }
        // console.log(total, "order");
        // return;
        return total;
    }

    function formatDate(date) {
        const mdate = new Date(date);
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return mdate.toLocaleDateString("en-US", options);
    }

    const OrderHistory = order; // []
    const ViewOrderdata = ViewOrder;

    // console.log(products[ViewOrder._id]);

    return (
        <>
            <div>
                <div className="section-ptb">
                    <Container>
                        <Row>
                            <Sitebar />
                            <Col lg={8} className=" pr-5">
                                <Row>
                                    <Col lg={!ViewOrderdata ? 12 : 10}>
                                        {!ViewOrderdata ? (
                                            <div className="table-responsive">
                                                {OrderHistory.length > 0 ? (
                                                    <table className="table orderhistory-table mb-0">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col border-right-0">Order</th>
                                                                <th scope="col border-right-0">Date</th>
                                                                <th scope="col border-right-0">Total</th>
                                                                <th scope="col border-left-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {OrderHistory.map((Ordervalue) => (
                                                                <tr key={Ordervalue.orderId}>
                                                                    <td className="link">{Ordervalue.orderId}</td>
                                                                    <td>{formatDate(Ordervalue.updatedAt)}</td>
                                                                    <td>
                                                                        <strong>
                                                                            $
                                                                            {getTotalPrice(Ordervalue).toLocaleString(
                                                                                navigator.language,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                }
                                                                            )}
                                                                        </strong>
                                                                        <br />
                                                                        <span className="light-text">
                                                                            {Ordervalue.items.length}{" "}
                                                                            {Ordervalue.items.length === 1
                                                                                ? "item"
                                                                                : "items"}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Link
                                                                            className="action-button"
                                                                            onClick={() => onViewOrder(Ordervalue)}
                                                                            href="#"
                                                                        >
                                                                            View
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <div style={{ height: "60vh" }}>
                                                        <div className="outer-div">
                                                            <div className="inner-div">
                                                                <div className="no-order-text">
                                                                    <span>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="20px"
                                                                            height="20px"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <path
                                                                                fill="currentColor"
                                                                                d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8z"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span>No order has been made yet.</span>
                                                                </div>
                                                                <a href="/" className="browse-product-btn">
                                                                    Browse Products
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                <h4>Order details</h4>
                                                {(products[ViewOrder._id] ?? []).map((product) => (
                                                    <Ordercarddetails
                                                        key={product.id}
                                                        product={product}
                                                        orderId={ViewOrder.orderId}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default OrderHistory;
