import { jsPDF } from "jspdf";
import { fabric } from "fabric";
// requests
import filesRequests from "../requests/files";
import productUtilsRequests from "../requests/productsUtils";
// utils
import { wait } from "./wait";
import { createFabricQRImage } from "./createFabricQRImage";
import { assingObjectsToCanvas, setCustomFontsWhenLoaded, setCanvasAtResoution } from "./canvas";
// constants
import { CANVAS_SIZE } from "./constants";

// global variables
let imageWidth, imageHeight, tempCanvas;

function _getScaleMultiplier(isForQR = false) {
    if (isForQR) {
        if (imageWidth <= 3000) {
            return (imageWidth * 2) / CANVAS_SIZE;
        }
        return imageWidth / CANVAS_SIZE;
    }

    return imageWidth / CANVAS_SIZE;
}

function _getResizedWithAndHeight(maxWidth = 4000, maxHeight = 4000) {
    let width = imageWidth;
    let height = imageHeight;

    // Change the resizing logic
    if (width > height) {
        if (width > maxWidth) {
            height = Math.floor(height * (maxWidth / width));
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.floor(width * (maxHeight / height));
            height = maxHeight;
        }
    }

    return { width, height };
}

async function _addHighResolutionQRCode(QRText) {
    const scaleMultiplierForQR = _getScaleMultiplier(true);
    const scaleMultiplier = _getScaleMultiplier(false);
    // remove low resolution qr image
    const currentQRGroup = tempCanvas.getObjects().find((obj) => obj.type === "group");

    if (currentQRGroup) {
        const { left, top } = currentQRGroup;
        const { width } = currentQRGroup._objects[1];
        const newImageWidth = Math.ceil(width * scaleMultiplierForQR);
        tempCanvas.remove(currentQRGroup);

        // re-create qr code with better resolution
        const fabricQrImage = await createFabricQRImage({
            size: newImageWidth,
            phoneNumber: QRText,
            // left: left * scaleMultiplier,
            // top: top * scaleMultiplier
            left,
            top,
            pageName: "download",
            posterWidth: tempCanvas.width,
        });

        if (imageWidth <= 3000) {
            fabricQrImage.scale(0.52);
        }

        fabricQrImage.setCoords();
        tempCanvas.add(fabricQrImage);

        await wait(50); // the image needs a little bit of time before get rendered

        // re-scale fontSize if the object lose his fontSize rescaled value
        for (let obj of tempCanvas.getObjects()) {
            if (obj.label === "QR Code" || obj.label === "QR Code Image" || obj.type === "group") continue;

            if (obj.originalFontSize !== obj.fontSize / scaleMultiplier) {
                console.log("fontSize scaled again");
                obj.fontSize *= scaleMultiplier;
            }
        }

        tempCanvas.renderAll();
    }
}

async function _setObjectsAndScale(canvasObjects) {
    const scaleMultiplier = _getScaleMultiplier();

    await assingObjectsToCanvas(tempCanvas, canvasObjects);
    await setCustomFontsWhenLoaded(tempCanvas);

    // scale properties
    for (let obj of tempCanvas.getObjects()) {
        if (obj.label === "QR Code" || obj.label === "QR Code Image") continue;

        // obj.lineHeight *= scaleMultiplier;
        // obj.charSpacing *= scaleMultiplier;

        obj.originalFontSize = obj.fontSize; //  important to check if font resize was good
        obj.originalTextWidth = obj.width; //  important to check if font resize was good

        obj.width *= scaleMultiplier;
        obj.left *= scaleMultiplier;
        obj.top *= scaleMultiplier;
        obj.fontSize *= scaleMultiplier;
        obj.setCoords();
    }

    tempCanvas.renderAll();
}

function createPDFAndDownload(productName, sizeObj) {
    let width, height, unit, fileName;
    if (!sizeObj) {
        unit = "px"; // or in (inches)
        width = imageWidth; // or 8.5
        height = imageHeight; // or 11
        fileName = productName + " - full size";
    } else {
        unit = "in";
        width = sizeObj.width;
        height = sizeObj.height;
        fileName = productName + ` ${sizeObj.width} x ${sizeObj.height} inches`;
    }
    const opts = {
        orientation: imageHeight > imageWidth ? "p" : "l",
        format: [width, height],
        unit,
    };
    const pdf = new jsPDF(opts);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const imgElmt = new Image(imageWidth, imageHeight);
    imgElmt.src = tempCanvas.toDataURL({
        format: "jpeg",
    });
    pdf.addImage(imgElmt, "JPEG", 0, 0, pdfWidth, pdfHeight);
    // download
    pdf.save(`${fileName}.pdf`);
}

async function createPDFAndDownloadV2(productName, sizeObj) {
    let width, height, unit, fileName;
    const { width: resizedWidth, height: resizedHeight } = _getResizedWithAndHeight();

    if (!sizeObj) {
        unit = "px";
        width = resizedWidth;
        height = resizedHeight;
        fileName = productName + " - full size";
    } else {
        unit = "in";
        width = sizeObj.width;
        height = sizeObj.height;
        fileName = productName + ` ${sizeObj.width} x ${sizeObj.height} inches`;
    }

    const opts = {
        orientation: imageHeight > imageWidth ? "p" : "l",
        format: [width, height],
        unit,
    };
    const pdf = new jsPDF(opts);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const imgElmt = new Image(resizedWidth, resizedHeight);
    imgElmt.src = await getSmallerCanvasBase64ImageForJPEG();

    pdf.addImage(imgElmt, "JPEG", 0, 0, pdfWidth, pdfHeight);
    // download
    pdf.save(`${fileName}.pdf`);
}

async function createPDFAndReturnAsBlob() {
    const pdf = new jsPDF({
        orientation: imageHeight > imageWidth ? "portrait" : "landscape",
        unit: "px",
        format: [imageWidth, imageHeight],
    });

    const imgElmt = new Image(imageWidth, imageHeight);
    imgElmt.src = await _getSmallerCanvasBase64Image();
    pdf.addImage(imgElmt, "JPEG", 0, 0, imageWidth, imageHeight);
    return pdf.output("blob");
}

async function generateVideoAndGetURL(imageNameWithExt, songSelected) {
    if (!songSelected) return "";
    console.log("try to generate video");

    const songName = songSelected.path.split("/").pop();
    const base64Image = await _getSmallerCanvasBase64Image(1200);

    const savedVideoUrl = await productUtilsRequests.createVideo({
        base64Image,
        songName,
        imageName: imageNameWithExt,
        originalImageWidth: 1200,
    });

    return savedVideoUrl;
}

const _cloneCanvas = () => {
    return new Promise((resolve) => {
        const canvasElmt = document.createElement("canvas");
        const cloneCanvas = new fabric.Canvas(canvasElmt);
        cloneCanvas.enableRetinaScaling = false;

        cloneCanvas.loadFromJSON(JSON.stringify(tempCanvas), () => {
            cloneCanvas.renderAll();
            resolve(cloneCanvas);
        });
    });
};

const _getSmallerCanvasBase64Image = async (newWidth = 1500) => {
    // set smaller canvas to get lightweight image, eg 5000px width to 1200px width
    const cloned = await _cloneCanvas();

    // test with resize
    setCanvasAtResoution(cloned, newWidth, imageWidth, { width: imageWidth, height: imageHeight });

    const resizedBase64 = cloned.toDataURL({
        format: "jpeg",
    });
    return resizedBase64;
};

const getSmallerCanvasBase64ImageForJPEG = async () => {
    const { width, height } = _getResizedWithAndHeight();
    const base64 = await _getSmallerCanvasBase64Image(width);

    const img = new Image();
    img.src = base64;

    return new Promise((resolve) => {
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        };
    });
};

const getCanvas = () => {
    return tempCanvas;
};

const getPosterbase64Image = (format = "jpeg") => {
    const base64Image = tempCanvas.toDataURL({
        format,
    });
    return base64Image;
};

function _checkForMovedBoxesAndReadjust(_tempCanvas) {
    const scaleMultiplier = _getScaleMultiplier();

    for (const obj of _tempCanvas.getObjects()) {
        if (obj.type === "textbox") {
            let extraCount = 0;
            // reduce font if box width is bigger than max width (specially for multiline boxes)
            // using a variable called "extraCount" to checking box width, because if you reduce the box width
            // and the text is still very large (fontSize) the box width  won't change
            // so we need to remember the last fontSize
            extraCount = obj.fontSize * scaleMultiplier;
            const scaledMaxWidth = obj.maxWidth * scaleMultiplier;

            while (obj.width > scaledMaxWidth) {
                extraCount--;
                obj.fontSize = extraCount;
                _tempCanvas.renderAll();
                obj.width = scaledMaxWidth;
                _tempCanvas.renderAll();
                console.log("reducing box width and font size to fit maxWidth");
            }
        }
    }
}

const initCanvas = ({ canvasObjects, product, QRText }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const canvasElmt = document.createElement("canvas");
            // set global canvas instance
            tempCanvas = new fabric.Canvas(canvasElmt);
            tempCanvas.enableRetinaScaling = false;

            const originalImageUrl = await filesRequests.getOriginalImage(product.productFilesId);

            // load image
            fabric.Image.fromURL(
                originalImageUrl,
                async function (img) {
                    tempCanvas.setDimensions({ width: img.width, height: img.height });

                    tempCanvas.setBackgroundImage(img, tempCanvas.renderAll.bind(tempCanvas), {
                        scaleX: 1,
                        scaleY: 1,
                    });

                    imageWidth = img.width;
                    imageHeight = img.height;

                    // call after background image established
                    await _setObjectsAndScale(canvasObjects);
                    await _addHighResolutionQRCode(QRText);
                    _checkForMovedBoxesAndReadjust(tempCanvas);

                    window.tempCanvas = tempCanvas;

                    resolve(tempCanvas);
                },
                {
                    crossOrigin: "anonymous",
                }
            );
        } catch (err) {
            console.error("Error preparing canvas", err.message);
            reject(err.message);
        }
    });
};

export {
    getCanvas,
    initCanvas,
    getPosterbase64Image,
    createPDFAndDownload,
    createPDFAndDownloadV2,
    createPDFAndReturnAsBlob,
    generateVideoAndGetURL,
    getSmallerCanvasBase64ImageForJPEG,
};
