/**
 *  Account Profile
 */
import React, { Component } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Sitebar from "./Sitebar";
import customer from "../../requests/customer";
import Footer from "../../layouts/footer/Footer";

class AccountProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            gender: "",
            dob: "",
            phoneno: "",
            email: "",
            address: "",
        };
    }

    async fetchProfile() {
        const customerId = localStorage.getItem("customerId");

        try {
            const userProfile = await customer.getCustomer({ customerId });
            this.setState({
                firstname: userProfile.firstname,
                lastname: userProfile.lastname,
                gender: userProfile.gender,
                dob: userProfile.dob,
                phoneno: userProfile.phoneno,
                email: userProfile.email,
                address: userProfile.address,
            });
        } catch (error) {
            console.error("Error in userProfile:", error);
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchProfile();
    }

    render() {
        const { firstname, lastname, gender, dob, phoneno, email, address } = this.state;
        return (
            <>
                <div>
                    <div className="section-ptb">
                        <Container>
                            <Row>
                                <Sitebar />
                                <Col lg={8} className="h-60vh">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="woocommerce-Address mt-5 mt-lg-0">
                                                <div className="woocommerce-Address-title">
                                                    <h5 class="mb-0">Profile Information</h5>
                                                    <Link className="edit" to="/Account/edit-profile">
                                                        Edit
                                                    </Link>
                                                </div>
                                                <div className="woocommerce-Address-info mt-4">
                                                    <ul class="list-unstyled mb-0">
                                                        <li>
                                                            <span>First name:</span>
                                                            <strong>{firstname}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Last name:</span>
                                                            <strong>{lastname}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Gender:</span>
                                                            <strong>{gender}</strong>
                                                        </li>
                                                        <li>
                                                            <span>DOB:</span>
                                                            <strong>{dob}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Phone no:</span>
                                                            <strong>{phoneno}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Email:</span>
                                                            <strong>{email}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Address:</span>
                                                            <strong>{address}</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default AccountProfile;
