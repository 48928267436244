import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Loader/customLoader";
import orderRequests from "../../requests/orders";
import Downloads from "../Downloads/downloads";

const ordercarddetails = ({ product, orderId }) => {
    const [canvas, setCanvas] = useState();
    const [inputsproducts, setInputsproducts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [enablePreview, setEnablePreview] = useState(false);
    const [productimage, setproductimage] = useState();
    const [productDownloadId, setProductDownloadId] = useState();
    const [productdata, setProductdata] = useState();
    const [InputsValues, setInputsValues] = useState();
    const [image, setimage] = useState();
    const [orderItem, setOrderItem] = useState();
    const [customizationData, setCustomizationData] = useState();
    const [downloadModal, setDownloadModal] = useState(false);
    const [downloadOptions, setDownloadOptions] = useState(false);
    const [productID, setProductID] = useState("");

    const navigate = useNavigate();

    // console.log(product, "product");
    function onEdit(e, product) {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/edit-purchase?productId=${product._id}&orderId=${orderId}`);
    }
    const toggleModalDownaload = () => setDownloadModal(!downloadModal);
    const getOrderData = () => {
        const orderdata = orderRequests
            .getOrderItem({
                productId: product._id,
                orderId: orderId,
            })
            .then((orderData) => {
                setOrderItem(orderData);
                setCustomizationData(orderData.customization_data);
                setimage(orderData.customized_item_preview);

                // console.log("orderData", orderData.customization_data);
            });
    };

    useEffect(() => {
        getOrderData();
    }, []);

    const onDownload = (e, product) => {
        // e.preventDefault();
        // const productimageid = product._id;
        // setProductDownloadId(productimageid);
        // navigate(`/download-files?productId=${product._id}&orderId=${orderId}`);
        const productimageid = product._id;
        console.log(productimageid, "productimageid");
        setProductID(productimageid);
        setDownloadOptions(true);
    };

    return isLoading ? (
        <div style={{ width: "100%", height: "200px", display: "flex", alignItems: "center" }}>
            <CustomLoader />
        </div>
    ) : (
        <div>
            {/* <Link to={`/card/${product?._id}`} className="single-order-box" key={product._id}> */}
            <div className="single-order-box">
                <img className="order-image" src={image} alt="" />
                {/* <canvas
                        id="dataUrl"
                        style={{ width: "100px", height: "120px", borderRadius: "10px", display: "none" }}
                    ></canvas> */}
                <div className="order-content">
                    <div className="text-div">
                        <h4 className="mb-0">{product.name}</h4>
                        <div>
                            <span>
                                <strong>Music: </strong>
                                {customizationData && JSON.parse(customizationData).song?.name
                                    ? JSON.parse(customizationData).song?.name
                                    : "No"}
                            </span>
                            <br />
                            <span>
                                <strong>QR:</strong>
                                {customizationData && JSON.parse(customizationData).QRText
                                    ? JSON.parse(customizationData).QRText
                                    : "No"}
                            </span>
                            <br />
                        </div>
                        <h4 className="bolder">
                            $
                            {(
                                product.price + (customizationData && JSON.parse(customizationData).song?.name ? 5 : 0)
                            ).toFixed(2)}
                        </h4>
                    </div>
                    <div className="btn-div">
                        <button className="edit-btn" onClick={(e) => onEdit(e, product)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36M20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"
                                />
                            </svg>{" "}
                            Edit
                        </button>
                        <button className="download-btn" onClick={(e) => onDownload(e, product)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-8 4v-5h2v3h12v-3h2v5z"
                                />
                            </svg>{" "}
                            Download
                        </button>
                    </div>
                </div>
            </div>
            {/* </Link> */}

            <Modal isOpen={downloadOptions} toggle={() => setDownloadOptions(false)}>
                <ModalHeader toggle={() => setDownloadOptions(false)}>Downloads</ModalHeader>
                <ModalBody className="modal-download">
                    <Downloads productID={productID} orderId={orderId} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ordercarddetails;
