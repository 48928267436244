import CategoriesList from "../Categories/categoriesList.jsx";
import SimchaInvitation from "./SimchaInvitation.jsx";
import "./styles/category.css";

const Category = () => {
    return (
        <section className="category-container d-flex justify-content-center" id="chooseCategorysec">
            <div className="category-wrap">
                <div className="text-center">
                    <p className="category-hd">What brand of happy has life handed you?</p>
                    <p className="section-subtitle">
                        We can feel the joy oozing from your fingertips. <br />
                        You’re itching to tell the world.
                        <br />
                        This is your chance to do it in style.
                    </p>
                </div>
                <CategoriesList />
                <SimchaInvitation />
            </div>
        </section>
    );
};

export default Category;
