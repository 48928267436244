import React, { useRef, useState } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import Attachment from "../../assets/icons/Attachment";
import CustomModalSendIcon from "../../assets/CustomModalSendIcon";
import mailRequests from "../../requests/mail";
import CircleLoader from "../Loader/circleLoader";
import Swal from "sweetalert2";

const CustomModal = ({ customModalOpen, setCustomModalOpen }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState({});
    const [modalLoading, setModalLoading] = useState(false);

    const handleButtonClick = () => fileInputRef.current.click();

    async function onSubmit() {
        const errors = {};
        if (!name) errors.name = "Name is required.";
        if (!email) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid email format.";
        }
        if (!message) errors.message = "Message is required.";
        if (!date) errors.date = "Date is required.";

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        setValidationErrors({});
        Swal.fire({
            title: "Submitting request",
            html: "Please wait while we process your request.",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const response = await mailRequests.sendCustomRequestMail({ name, email, message, date, file });
            if (response?.data?.error === false) {
                Swal.close();
                Swal.fire({
                    title: "Request Successful",
                    icon: "success",
                    text: "Your request was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                });

                closeModal();
            } else {
                throw new Error("Failed to submit request");
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: "Error",
                icon: "error",
                text: error.message || "Failed to submit request",
                timer: 2000,
                showConfirmButton: false,
            });
        }
    }

    const closeModal = () => {
        setCustomModalOpen(false);

        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
            backdrop.parentNode.removeChild(backdrop);
        }

        document.body.classList.remove("modal-open");
    };

    const handleFileInputChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div
            className={`modal fade ${customModalOpen ? "show" : ""}`}
            id="customModal"
            style={{ display: customModalOpen ? "block" : "none" }}
            data-bs-backdrop="true"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!customModalOpen}
        >
            <div className="modal-dialog scrollable reach-out-modal-container">
                <div className="modal-content">
                    <div
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close-btn"
                        onClick={() => setCustomModalOpen(false)}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div className="modal-body">
                        <p className="reach-out-hd custom-modal-hd">Custom Design</p>
                        <div className="reach-modal-input-wrap custom-modal-input-wrap">
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="reach-input"
                                type="text"
                                required
                                placeholder="Name"
                            />
                            {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="reach-input"
                                type="email"
                                required
                                placeholder="Email"
                            />
                            {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}
                            <div className="textarea-icon-wrap">
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Hi, I need a poster for..."
                                    className="reach-out-text-area custom-modal-textarea"
                                ></textarea>

                                <div className="reach-out-btn-wrap custom-modal-btn-wrap">
                                    <div className="date-and-file-wrap">
                                        <button className="custom-modal-attchment-btn" onClick={handleButtonClick}>
                                            <Attachment />
                                            <span>Add Attachment</span>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                            onChange={handleFileInputChange}
                                        />
                                        <input
                                            type="date"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            className="inputDate"
                                        />
                                    </div>
                                    <div className="custom-modal-submit-btn-wrap">
                                        <button onClick={onSubmit} className="custom-modal-submit-btn">
                                            Submit <CustomModalSendIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {validationErrors.message && <p className="error-message">{validationErrors.message}</p>}
                            {validationErrors.date && <p className="error-message">{validationErrors.date}</p>}
                            <div className="custom-modal-not-wrap">
                                <p className="custom-modal-note">
                                    <span className="note-hd">Please Note:</span> Your invitation will be custom
                                    designed for you and you will be the first one to use it. Once your Simcha has
                                    passed, it will be available for purchase in the template section.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
