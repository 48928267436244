import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AppForm from "./form";
import { useCart } from "../../context/cartContext";
import CircleLoader from "../Loader/circleLoader";
import "./checkout.css";
import "../../component/Downloads/DownloadModal";
import DownloadModal from "../../component/Downloads/DownloadModal";
import { previewImage } from "antd/lib/upload/utils";

const CheckoutModal = ({ checkout, setCheckout }) => {
    const toggleModal = () => setCheckout(false);
    const { cartItems, reloadCart } = useCart();
    const [modalLoading, setModalLoading] = useState(true);
    const [downloadModal, setDownloadModal] = useState(false);

    const toggleModalDownaload = () => setDownloadModal(!downloadModal);
    const cartItemsArray = Object.values(cartItems);

    const totalPrice = cartItemsArray
        .reduce((sum, item) => {
            const customizationData = (() => {
                try {
                    return JSON.parse(item.customizationData);
                } catch (error) {
                    console.error("Invalid JSON in customizationData:", error);
                    return {};
                }
            })();
            const hasMusic = customizationData.song?.name ? true : false;
            const musicCharge = hasMusic ? 5 : 0;
            return sum + (item.productDetails.price + musicCharge) * item.count;
        }, 0)
        .toFixed(2);
    const cartProducts = cartItemsArray.map((item) => {
        const customizationData = JSON.parse(item.customizationData || "{}");
        const hasSong = customizationData.song?.name;

        return {
            productId: item.product_id,
            price: (hasSong ? item.productDetails.price + 5 : item.productDetails.price).toFixed(2),
            name: item.productDetails.name,
            quantity: 1,
            customizeItemPreview: item.customizeItemPreview,
            customizationData: item.customizationData,
            cart_item_key: item._id,
        };
    });

    const handleFormLoaded = () => {
        setModalLoading(false);
    };
    return (
        <div>
            <Modal isOpen={checkout} toggle={toggleModal}>
                {/* <div className="modal" isOpen={checkout}> */}
                {/* <div className="modal-dialog scrollable reach-out-modal-container modal-dialog-centered"> */}
                <div className="modal-content">
                    <ModalHeader>Checkout</ModalHeader>
                    {modalLoading && (
                        <div className="spinner-overlay">
                            <CircleLoader color="#000" loading={modalLoading} size={50} />
                        </div>
                    )}

                    <ModalBody className="modal" style={{ display: modalLoading ? "none" : "block" }}>
                        <div className="cart-payment-container">
                            <div className="cart-payment-form">
                                <div className="cart-input-wrap">
                                    <AppForm
                                        total={totalPrice}
                                        products={cartProducts}
                                        onFormLoaded={handleFormLoaded}
                                        closeModal={toggleModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </div>
                {/* </div> */}
                {/* </div> */}
            </Modal>

            <DownloadModal isOpen={downloadModal} closeModal={toggleModalDownaload} />
        </div>
    );
};

export default CheckoutModal;
