import axios from "axios";
import { API_BASE_URL } from "../config";
import { getToken } from "../utils/token";

const customer = {};

const setDefaultOptions = ({ headers = {}, token, cancelToken }) => {
    const defaultHeaders = {
        "Content-Type": "application/json",
        ...headers,
    };
    if (token) {
        const authToken = getToken();
        if (authToken) {
            defaultHeaders["Authorization"] = `Bearer ${authToken}`;
        }
    }
    return {
        headers: defaultHeaders,
        ...(cancelToken && { cancelToken }),
    };
};

customer.getCustomer = async function ({ customerId }) {
    // console.log(customerId, "customerId");
    customerId = customerId.replace(/"/g, "");

    const url = `${API_BASE_URL}/customer/get-customer`;
    try {
        const response = await axios.post(url, { customerId }, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

customer.updateCustomer = async function (customerId, customerData) {
    customerId = customerId.replace(/"/g, "");

    const url = `${API_BASE_URL}/customer/update-customer`;
    try {
        const response = await axios.post(url, { customerId, customerData }, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

customer.checkEmail = async function (email) {
    const url = `${API_BASE_URL}/customer/check-email`;
    try {
        const response = await axios.post(url, { email }, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

export default customer;
