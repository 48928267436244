import React, { useEffect, useRef, useState } from "react";
import WrappedIfield from "./wrapped-ifield";
import { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import { API_BASE_URL } from "../../config";
import CouponsRequest from "../../requests/Coupons";
import { isCustomerLogin, customerEmail } from "../../utils/common";
import cartRequests from "../../requests/cart";
import Swal from "sweetalert2";
import customer from "../../requests/customer";
import { useCart } from "../../context/cartContext";
import "./checkout.css";
import DownloadModal from "../../component/Downloads/DownloadModal";

export default function AppForm({ total, products, onFormLoaded, closeModal }) {
    const isCustomer = isCustomerLogin();
    const isCustomerEmail = customerEmail() || "";
    const cardTokenRef = useRef();
    const cvvTokenRef = useRef();
    const [issuer, setIssuer] = useState("");
    const [amount, setAmount] = useState(2.36);
    const [firstName, setFirstName] = useState("John");
    const [lastName, setLastName] = useState("Doe");
    const [address, setAddress] = useState("123 Somewhere");
    const [city, setCity] = useState("Anywhere");
    const [addressState, setAddressState] = useState("NY");
    const [zip, setZip] = useState("98765");
    const [mobile, setMobile] = useState("1234567890");
    const [email, setEmail] = useState(isCustomerEmail);
    const [expiryDate, setExpiryDate] = useState("");
    const [gatewayResponse, setGatewayResponse] = useState("");
    const [password, setPassword] = useState("");
    const [emailExists, setEmailExists] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const { voidCart } = useCart();
    const [downloadModal, setDownloadModal] = useState(false);
    const [couponcode, setCouponcode] = useState("");
    const [calculatedTotal, setCalculatedTotal] = useState(total);
    const [tokenAppliedtext, setTokenAppliedtext] = useState("");
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    // Toggle function to open/close modal
    const cardRef = useRef();
    const cvvRef = useRef();
    useEffect(() => {
        const iframe = document.querySelector("iframe.ifields");
        if (iframe) {
            iframe.onload = () => {
                if (onFormLoaded) {
                    onFormLoaded();
                }
            };
        } else {
            if (onFormLoaded) {
                onFormLoaded();
            }
        }
    }, [onFormLoaded]);

    const checkEmailInDatabase = async (email) => {
        try {
            const response = await customer.checkEmail(email);

            console.log(response, "data");

            if (response.exists) {
                setEmailExists(true);
                setUserInfo(response.user);
                setEmail(response.user.email);
            } else {
                setEmailExists(false);
                setUserInfo(null);
            }
        } catch (error) {
            console.error("Error checking email:", error);
        }
    };
    const handleEmailChange = async (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (emailValue) {
            await checkEmailInDatabase(emailValue);
        }
    };

    const handleCouponCode = async (e) => {
        if (e.target.value) {
            const Couponsdata = await CouponsRequest.getCouponsbycode(e.target.value);
            console.log(Couponsdata, "Couponsdata");
            if (Couponsdata) {
                const discount = Couponsdata.discount;
                const discountedTotal = (total * (1 - discount / 100)).toFixed(2);
                setCalculatedTotal(discountedTotal);
                setCouponcode(Couponsdata);
                setTokenAppliedtext(`(Coupon applied: ${Couponsdata.discount}% off)`);
            } else {
                setTokenAppliedtext(`(Invalid Coupon code)`);
                setCalculatedTotal(total);
            }
        } else {
            setTokenAppliedtext(``);
            setCalculatedTotal(total);
        }
    };

    const handleCardToken = (data) => {
        cardTokenRef.current = data.xToken;
    };

    const handleCvvToken = (data) => {
        cvvTokenRef.current = data.xToken;
    };

    const handleExpiryDateChange = (e) => {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length > 2) {
            input = input.slice(0, 2) + "/" + input.slice(2);
        }
        setExpiryDate(input);
    };

    const submitToGateway = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Promise.all([cardRef.current.getToken(), cvvRef.current.getToken()]);
            const tokenWaiter = new Promise((resolve, reject) => {
                let attempts = 0;
                const interval = setInterval(() => {
                    if (cardTokenRef.current && cvvTokenRef.current) {
                        clearInterval(interval);
                        resolve();
                    } else if (attempts > 60) {
                        clearInterval(interval);
                        if (!cardTokenRef.current) {
                            reject(new Error("Card Number is required"));
                            setLoading(false);
                        } else {
                            reject(new Error("CVV is required"));
                            setLoading(false);
                        }
                    }
                    attempts++;
                    console.log("🚀 ~ interval ~ attempts:", attempts);
                }, 100);
            });

            await tokenWaiter;

            if (!cardTokenRef.current) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Card number is required",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Failed to retrieve card or CVV token");
            }

            if (!cvvTokenRef.current) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "CVV is required",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Failed to retrieve card or CVV token");
            }

            Swal.fire({
                title: "Processing Payment...",
                html: "Please wait while we process your payment.",
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let customerData = {
                email: email,
                password: password,
                emailExists: emailExists,
                customerId: userInfo ? userInfo._id : null,
                guestId: localStorage.getItem("guest_user_id"),
            };
            if (isCustomer) {
                let customerId = isCustomer;
                let existingCustomer = await customer.getCustomer({ customerId });

                if (existingCustomer) {
                    customerData = {
                        email: existingCustomer.email,
                        emailExists: true,
                        customerId: existingCustomer._id,
                        guestId: null,
                    };
                }
            }

            let productsdata = null;
            const customerId = isCustomerLogin();
            if (customerId) {
                productsdata = await cartRequests.getFromCart(customerId);
            } else {
                productsdata = await cartRequests.getFromCartGuest(localStorage.getItem("guest_user_id"));
            }

            let totalAmount = products.reduce((acc, item) => acc + item.price * item.quantity, 0);
            let netAmount = totalAmount;

            if (couponcode) {
                console.log("couponcode", couponcode);
                const discountPercentage = couponcode.discount;
                if (discountPercentage >= 0 && discountPercentage <= 100) {
                    totalAmount = (totalAmount * (1 - discountPercentage / 100)).toFixed(2);
                }
            } else {
                totalAmount = totalAmount.toFixed(2);
            }

            if (totalAmount < 1) {
                setLoading(false);
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Please select a product",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Please select a product");
            }
            // return;

            let request = {
                xSoftwareName: "Test-React-iFields",
                xSoftwareVersion: "1.0",
                xVersion: "5.0.0",
                xCommand: "cc:sale",
                xAmount: totalAmount,
                xCardnum: cardTokenRef.current,
                xBillFirstName: firstName,
                xBillLastName: lastName,
                xBillStreet: address,
                xBillCity: city,
                xBillZip: zip,
                xBillState: addressState,
                xBillMobile: mobile,
                xEmail: email,
                xExp: expiryDate.replace("/", ""),
                xCvv: cvvTokenRef.current,
                xAllowDuplicate: "TRUE",
            };

            const response = await fetch(`${API_BASE_URL}/cart/process-payment`, {
                method: "POST",
                body: JSON.stringify({
                    card: request,
                    customer: customerData,
                    products: products,
                    canvas: productsdata.data[0],
                    coupondata: couponcode || null,
                    netAmount: netAmount,
                }),
                headers: { "Content-Type": "application/json" },
            });

            const responseBody = await response.json();
            if (responseBody.success) {
                setGatewayResponse(responseBody.data);

                Swal.fire({
                    title: "Payment Successful",
                    icon: "success",
                    text: "Your payment was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                voidCart();
                // removeFromCart({ _id: id });
                // console
                localStorage.setItem("customerId", JSON.stringify(responseBody.payments));

                setDownloadModal(true);
                // await purchasesRequests.initEditCount(wooCartItemKey, orderId);
                // Clear cart
                // await cartRequests.clearCart(customerId);
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: responseBody.data[0].xError,
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            setLoading(false);

            Swal.close();
            Swal.fire({
                title: "Error",
                icon: "error",
                text: error.message,
                timer: 2000,
                showConfirmButton: false,
            });
            setGatewayResponse({ message: error.message });
        }
    };

    useEffect(() => {
        if (gatewayResponse && gatewayResponse.xError !== undefined) {
            if (gatewayResponse.xError === "") {
                Swal.fire({
                    title: "Payment Successfully",
                    icon: "success",
                    text: "Your payment was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
            } else {
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: gatewayResponse.xError,
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
            }
        }
    }, [gatewayResponse]);

    const handleCloseDownloadModal = () => {
        setDownloadModal(false);
        closeModal();
    };
    return (
        <div className="checkout-container">
            <div className="main cart-payment-container">
                <form onSubmit={submitToGateway}>
                    <div className="columns">
                        <div className="column">
                            <section className="box card-box">
                                <div className="field d-none">
                                    <label className="label">Amount</label>
                                    <input
                                        className="input"
                                        type="text"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                                {!isCustomer && (
                                    <>
                                        <div style={{ marginBottom: "13px" }}>
                                            <label
                                                htmlFor="email"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    marginBottom: "3px",
                                                }}
                                            >
                                                Email:
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required
                                                style={{
                                                    width: "98%",
                                                    boxShadow: "none",
                                                    fontFamily: "Nexa !important",
                                                    backgroundColor: "white",
                                                    border: "1px solid rgb(219, 219, 219)",
                                                    borderRadius: "14px",
                                                    color: "#4b4b4b",
                                                    height: "3em",
                                                    fontSize: "16px",
                                                    lineHeight: "1.5",
                                                    padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                    outline: "none",
                                                    height: "60px",
                                                    border: "2px solid #d8d7d7d5",
                                                    borderRadius: "15px",
                                                    padding: "25px 0px 25px 20px",
                                                    fontWeight: "600",
                                                }}
                                            />

                                            {!showPassword && (
                                                <button
                                                    onClick={(e) => setShowPassword(true)}
                                                    class="create_password_btn"
                                                    type="button"
                                                >
                                                    Create password
                                                </button>
                                            )}
                                        </div>
                                        {showPassword && !emailExists && (
                                            <div className="password-form-group">
                                                <label
                                                    htmlFor="password"
                                                    style={{ fontSize: "16px", fontWeight: "600", marginBottom: "3px" }}
                                                >
                                                    Password: <small>(optional)</small>
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    style={{
                                                        width: "98%",
                                                        boxShadow: "none",
                                                        backgroundColor: "white",
                                                        border: "1px solid rgb(219, 219, 219)",
                                                        borderRadius: "14px",
                                                        color: "#4b4b4b",
                                                        height: "3em",
                                                        fontSize: "17px",
                                                        lineHeight: "1.5",

                                                        padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                        outline: "none",
                                                        height: "60px",
                                                        border: "2px solid #d8d7d7d5",
                                                        borderRadius: "15px",
                                                        padding: "25px 0px 25px 20px",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                <div className="field">
                                    <p className="label">Card Number</p>
                                    <WrappedIfield
                                        ifieldType={CARD_TYPE}
                                        onIssuer={setIssuer}
                                        onToken={handleCardToken}
                                        ref={cardRef}
                                    />
                                </div>
                                <div className="d-flex row expiry_container">
                                    <div className="field col-md-6">
                                        <p
                                            className="label"
                                            style={{ fontSize: "16px", fontWeight: "600", marginBottom: "3px" }}
                                        >
                                            Expiry Date
                                        </p>
                                        <input
                                            className="input cardExpiry"
                                            type="text"
                                            placeholder="MM/YY"
                                            value={expiryDate}
                                            onChange={handleExpiryDateChange}
                                            maxLength={5}
                                            required
                                            style={{
                                                width: "100%",
                                                boxShadow: "rgba(10, 10, 10, 0.1) 0px 1px 2px inset",
                                                backgroundColor: "white",
                                                border: "1px solid rgb(219, 219, 219)",
                                                borderRadius: "14px",
                                                color: "rgb(54, 54, 54)",
                                                height: "3em",
                                                fontSize: "17px",
                                                lineHeight: "1.5",
                                                padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                                outline: "none",
                                                height: "60px",
                                            }}
                                        />
                                    </div>

                                    <div className="field col-md-6">
                                        <p className="label"> Card CVV</p>
                                        <WrappedIfield ifieldType={CVV_TYPE} onToken={handleCvvToken} ref={cvvRef} />
                                    </div>
                                </div>
                                <div className="field coupon-code-form-group">
                                    <p
                                        className="label"
                                        style={{ fontSize: "16px", fontWeight: "600", marginBottom: "3px" }}
                                    >
                                        Coupons code
                                    </p>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder=" Coupons code"
                                        onChange={handleCouponCode}
                                        style={{
                                            width: "100%",
                                            boxShadow: "rgba(10, 10, 10, 0.1) 0px 1px 2px inset",
                                            backgroundColor: "white",
                                            border: "1px solid rgb(219, 219, 219)",
                                            borderRadius: "14px",
                                            color: "rgb(54, 54, 54)",
                                            height: "3em",
                                            fontSize: "17px",
                                            lineHeight: "1.5",
                                            padding: "calc(0.375em - 1px) calc(0.625em - 1px)",
                                            outline: "none",
                                            height: "60px",
                                        }}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="total-price-container">
                        <p className="total-price">{`Total \$${calculatedTotal}`}</p>
                        <p className="coupon-code-applied">{tokenAppliedtext}</p>
                    </div>

                    <div className="place-order-container">
                        <button className="place-order-btn " type="submit" disabled={loading}>
                            {loading ? "Loading..." : " Place Order"}
                        </button>
                    </div>
                </form>
            </div>

            <DownloadModal isOpen={downloadModal} closeModalDownload={handleCloseDownloadModal} />
        </div>
    );
}
