/**
 * Shop Page Side Bar Filter
 */
import React, { memo } from "react";
// hooks
import { useCategories } from "../../context/categoriesContext";
// custom components
import CustomLoader from "../../component/Loader/customLoader";
import CateogryFilterItem from "./CategoryFilterItem";

const SideFilter = memo(({ onSubCategoryChange, tagsByCategory, onTagChecked }) => {
    const { isLoading, categories } = useCategories();

    return (
        <div>
            <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                {isLoading ? (
                    <CustomLoader width={50} height={50} />
                ) : (
                    <div className="cateogry-filter">
                        {categories.map((c) => (
                            <CateogryFilterItem
                                key={c._id}
                                categoryName={c.name}
                                subCategories={c.subCategories}
                                categoryColor={c.color}
                                friendlyUrlName={c.friendlyUrlName}
                                categoryIcon={c.imageId?.path}
                                onSubCategoryChange={onSubCategoryChange}
                                tagsByCategory={tagsByCategory}
                                onTagChecked={onTagChecked}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
});

export default SideFilter;
