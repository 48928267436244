import { useState } from "react";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { useNavigate } from "react-router-dom";
import CustomLoader from "../Loader/customLoader";
import Downloads from "./downloads";

const ordercarddetails = ({ product, orderId }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [downloadOptions, setDownloadOptions] = useState(false);
    const [productID, setProductID] = useState(false);

    const navigate = useNavigate();

    function onEdit(e, product) {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/edit-purchase?productId=${product._id}&orderId=${orderId}`);
    }

    const onDownload = (e, product) => {
        const productimageid = product._id;
        setDownloadOptions(true);

        setProductID(productimageid);
    };

    return isLoading ? (
        <div style={{ width: "100%", height: "200px", display: "flex", alignItems: "center" }}>
            <CustomLoader />
        </div>
    ) : (
        <div>
            {/* <canvas
                        id="dataUrl"
                        style={{ width: "100px", height: "120px", borderRadius: "10px", display: "none" }}
                    ></canvas> */}
            <div className="order-content">
                <div className="text-div">
                    <h4 className="mb-0">{product.name}</h4>

                    <h4 className="bolder">${product.price}</h4>
                </div>

                <div className="btn-div">
                    <button className="download-btn" onClick={(e) => onDownload(e, product)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-8 4v-5h2v3h12v-3h2v5z"
                            />
                        </svg>{" "}
                        Download
                    </button>
                </div>
            </div>
            <Modal isOpen={downloadOptions} toggle={() => setDownloadOptions(false)}>
                <ModalHeader toggle={() => setDownloadOptions(false)}>Downloads</ModalHeader>
                <ModalBody className="modal-download">
                    <Downloads productID={product?._id} orderId={orderId} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ordercarddetails;
