import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Downloadspdf from "./Downloadspdf";

import Downloadsjpg from "./Downloadsjpg";
import { isCustomerLogin } from "../../utils/common";
import orderRequests from "../../requests/orders";
import customerRequest from "../../requests/customer";
import "./style/downloads.css";
import productRequests from "../../requests/products";
import OrderContent from "./OrderContent";
const DownloadModal = ({ isOpen, closeModalDownload }) => {
    const [order, setOrder] = useState([]);
    const [products, setProdcuts] = useState({});

    const [viewOrder, setViewOrder] = useState(null);
    const [customer, setCustomer] = useState(null);

    // Fetch orders
    const fetchOrders = useCallback(async () => {
        const customerId = isCustomerLogin();
        if (!customerId) return;
        try {
            const obj = await orderRequests.getOrderItems({ customerId });
            setOrder(obj.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    }, []);

    // Fetch customer profile
    const fetchProfile = useCallback(async () => {
        const customerId = localStorage.getItem("customerId");
        if (!customerId) return;
        try {
            const userProfile = await customerRequest.getCustomer({ customerId });
            console.log(userProfile, "userProfile");
            if (userProfile) setCustomer(userProfile);
        } catch (error) {
            console.error("Error fetching profile:", error);
        }
    }, []);

    async function fetchProducts(order) {
        const items = order.items;
        const result = [];

        for (let i = 0; i < items.length; i++) {
            const product = await productRequests.getProduct({ productId: items[i].productId });

            result.push(product);
        }

        setProdcuts({ ...products, [order._id]: result });
    }

    function onViewOrder(data) {
        // console.log(data, "data");

        if (products[data._id] == null) {
            fetchProducts(data);
        }
        setViewOrder(data);

        // toggle();
    }

    useEffect(() => {
        fetchOrders();
        fetchProfile();
    }, [isOpen, fetchOrders, fetchProfile]);
    const handleModalClose = () => {
        // Notify parent by invoking the callback
        closeModalDownload();
    };
    const getTotalPrice = (order) => order.items.reduce((total, item) => total + item.price, 0);

    const formatDate = (date) =>
        new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

    return (
        <Modal isOpen={isOpen} toggle={handleModalClose}>
            <ModalHeader toggle={handleModalClose}>Downloads</ModalHeader>
            <ModalBody className=" modal-downalod">
                <div className="dp__container downloads-class page-class">
                    <div className="dp__title">Thank you for your purchase</div>
                    <div className="dp__buttons">
                        <Container>
                            <Row>
                                <Col lg={12} className="mt-4 pr-5">
                                    <Row>
                                        <Col lg={12}>
                                            {!viewOrder ? (
                                                <div className="table-responsive">
                                                    {order.length > 0 ? (
                                                        <table className="table orderhistory-table mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order</th>
                                                                    <th>Date</th>

                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {order.map((Ordervalue) => (
                                                                    <tr key={Ordervalue.orderId}>
                                                                        <td>{Ordervalue.orderId}</td>
                                                                        <td>{formatDate(Ordervalue.updatedAt)}</td>

                                                                        <td>
                                                                            <Link
                                                                                className="action-button"
                                                                                onClick={() => onViewOrder(Ordervalue)}
                                                                                href="#"
                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <p>No orders available.</p>
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    {(products[viewOrder._id] ?? []).map((product) => (
                                                        <OrderContent
                                                            key={product.id}
                                                            product={product}
                                                            orderId={viewOrder.orderId}
                                                        />
                                                    ))}
                                                    {/* Additional order details */}
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DownloadModal;
