/**
 *  Shop Main Page
 */
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
// custom components
import ProductListItem from "../../widgets/ProductListItem";
import SideFilter from "../../widgets/shopfilter/SideFilter";
import CustomLoader from "../Loader/customLoader";
import NotFound from "../NotFound/notFound";
// requests
import productRequests from "../../requests/products";
import tagCategoriesRequests from "../../requests/tagCategories";
import tagsRequests from "../../requests/tags";
// custom hooks
import { useIsMounted } from "../../hooks/useIsMounted";
import { useCategories } from "../../context/categoriesContext";
// utils
import postToParent from "../../utils/postToParent";
import { getUrlQuery } from "../../utils/url";
import filterIcon from "../../assets/images/icons/filter-icon.svg";
// style
import "./styles/productList.css";
import Footer from "../../layouts/footer/Footer";

const ShopPage = () => {
    const { categoryFriendlyName } = useParams();
    // console.log(categoryFriendlyName,"categoryFriendlyName");

    const isMounted = useIsMounted();
    const { categories, isLoading: isLoadingCategories } = useCategories();

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [subCategoriesSelected, setSubCategoriesSelected] = useState([]);
    const [activeCategoryColors, setActiveCategoryColors] = useState();
    const [productTags, setProductTags] = useState([]);
    const [tagsByCategory, setTagsByCategory] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagCategories, setTagCategories] = useState([]);

    // get initial data
    useEffect(() => {
        setIsLoading(true);
        setSubCategoriesSelected([]);

        productRequests.getProductsByCategoryFriendlyName(categoryFriendlyName).then((_products) => {
            if (isMounted.current) {
                setProducts(_products);
                setProductTags([...new Set(_products.map((item) => item.tags).flat())]);
                setIsLoading(false);
            }
        });
    }, [categoryFriendlyName, isMounted]);

    // set subcategory if exist in wordpress url
    useEffect(() => {
        const { sub } = getUrlQuery();
        // console.log("setSubcategory: " + sub, categories);
        if (sub && categories.length) {
            const categoryInfo = categories?.find((c) => c.friendlyUrlName === categoryFriendlyName);
            const subCategoryInfo = categoryInfo?.subCategories?.find((c) => c.friendlyUrlName === sub);
            if (subCategoryInfo) {
                // console.log("I have found: " + subCategoryInfo)
                setSubCategoriesSelected([subCategoryInfo?._id]);
            }
        }
    }, [isMounted, categoryFriendlyName, categories]);

    useEffect(() => {
        tagsRequests.getTags().then(setTags);
        tagCategoriesRequests.getTagCategories().then(setTagCategories);
    }, []);

    // save every tag with parent tagCategory
    useEffect(() => {
        if (!tags.length || !productTags.length || !tagCategories.length) {
            return;
        }
        const filteredTags = tags.filter((item) => productTags.includes(item.tag));
        const tagsByCategory = [];

        for (const tagCategory of tagCategories) {
            if (!filteredTags.some((t) => t.category === tagCategory._id)) {
                continue;
            }
            const tagsOfCategory = filteredTags.filter((i) => i.category === tagCategory._id);

            tagsByCategory.push({
                ...tagCategory,
                tags: tagsOfCategory,
            });
        }

        setTagsByCategory(tagsByCategory);
    }, [tags, tagCategories, productTags]);

    // filter products by categories and/or tags
    useEffect(() => {
        let _filteredProducts = [],
            filteredProductsBySubCategory = [],
            filteredProductsByTags = [];

        if (subCategoriesSelected.length === 0 && selectedTags.length === 0) {
            _filteredProducts = [...products];
        } else {
            if (subCategoriesSelected.length) {
                filteredProductsBySubCategory = products.filter((p) =>
                    p.categoriesIds.some((cat) => subCategoriesSelected.includes(cat._id))
                );
                _filteredProducts = filteredProductsBySubCategory;
            }
            // filter by each family of tags
            if (selectedTags.length) {
                let toFilter = subCategoriesSelected.length ? filteredProductsBySubCategory : products;

                for (const tagCategory of tagsByCategory) {
                    const tagsNames = tagCategory.tags.map((t) => t.tag);

                    // check if there is at least one tag selected for that tags family
                    const atLeastOneSelected = tagsNames.some((tag) => selectedTags.includes(tag));
                    const exactTags = tagsNames.filter((tag) => selectedTags.includes(tag));

                    if (atLeastOneSelected) {
                        toFilter = toFilter.filter((product) => product.tags.some((tag) => exactTags.includes(tag)));
                    }
                }

                _filteredProducts = toFilter;
            }
        }

        setFilteredProducts(_filteredProducts);
    }, [subCategoriesSelected, selectedTags, products]);

    // reset tags selected every time category changes
    useEffect(() => {
        setSelectedTags([]);
        setProductTags([]);
        setTagsByCategory([]);
    }, [categoryFriendlyName]);

    // reset colors and wordpress values when change category
    useLayoutEffect(() => {
        const { sub } = getUrlQuery();

        if (isLoadingCategories) return;
        const category = categories.find((c) => c.friendlyUrlName === categoryFriendlyName);
        if (!category) {
            return;
        }
        const { color, backgroundColor } = category;
        const newUrlPathWP = `id=${categoryFriendlyName}${sub ? "&sub=" + sub : ""}`;

        setActiveCategoryColors({ color, backgroundColor });
        postToParent({ type: "color-change", color });
        postToParent({ type: "update-url-params", to: newUrlPathWP });
        return () => {
            postToParent({ type: "color-change", color: "#af40f7" });
        };
    }, [isLoadingCategories, categoryFriendlyName]);

    // toggle sidebar
    useLayoutEffect(() => {
        const filterBtn = document.getElementById("sidebar-filter-toggler-wrapper");

        function toggleSideBar() {
            const sidebar = document.getElementById("sidebar-category-filter");
            sidebar.classList.toggle("sidebar-visible");
        }

        filterBtn.addEventListener("click", toggleSideBar);

        return () => {
            filterBtn.removeEventListener("click", toggleSideBar);
        };
    }, []);

    const onSubCategoryChange = useCallback(
        (subCategoryId) => {
            let newSubCategoriesIds;

            // uncheck sub category if it's already checked
            if (subCategoriesSelected.includes(subCategoryId)) {
                newSubCategoriesIds = subCategoriesSelected.filter((cId) => cId !== subCategoryId);
            } else {
                newSubCategoriesIds = [...subCategoriesSelected, subCategoryId];
            }
            // all subcategories uncheckend, show all products again
            if (newSubCategoriesIds.length === 0) {
                setSubCategoriesSelected([]);
            } else {
                setSubCategoriesSelected(newSubCategoriesIds);
            }
        },
        [subCategoriesSelected, products]
    );

    const onTagChecked = (tag) => {
        const selectedTagsCopy = [...selectedTags];
        const index = selectedTagsCopy.indexOf(tag);
        if (index !== -1) {
            selectedTagsCopy.splice(index, 1);
        } else {
            selectedTagsCopy.push(tag);
        }
        setSelectedTags([...selectedTagsCopy]);
    };

    const { color } = activeCategoryColors || {};

    return (
        <>
            <div className="content-wrapper section-pt section-pb product-list-class page-class container-width px-3">
                <div className="all-flex-to-full-height">
                    <div id="sidebar-filter-toggler-wrapper">
                        <div id="filter-toggler-txt">Filter</div>
                        <button id="sidebar-toggler-button">
                            <img src={filterIcon} alt="filter" />
                        </button>
                    </div>

                    <div
                        className="flex-1 custom-row mx-0 justify-content-center justify-content-md-start"
                        style={{ position: "relative" }}
                    >
                        <div id="sidebar-category-filter" className="sidebar-category-filter col-xl-3 col-lg-3 desktop">
                            <div className="shop-sidebar-widgets new p-0">
                                <SideFilter
                                    onSubCategoryChange={onSubCategoryChange}
                                    tagsByCategory={tagsByCategory}
                                    onTagChecked={onTagChecked}
                                />
                            </div>
                        </div>
                        {!filteredProducts.length && !isLoading && <NotFound />}
                        <div className="products-content-wrapper">
                            {isLoading ? (
                                <div className="mt-5">
                                    <CustomLoader color={color} />
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <div
                                            className={`products-grid-container card-wrapper ${
                                                filteredProducts.length === 0 ? "m-0 p-0" : ""
                                            }`}
                                        >
                                            {filteredProducts.map((product) => (
                                                <ProductListItem
                                                    key={product._id}
                                                    product={product}
                                                    subCategoriesSelected={subCategoriesSelected}
                                                    selectedTags={selectedTags}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ zIndex: "9" }}>
                <Footer />
            </div>
        </>
    );
};

export default ShopPage;
