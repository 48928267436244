import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Account/AuthContext";
import "./style.css";
import useViewportWidth from "../../hooks/useViewportWidth";
import CartMenu from "../CartMenu/CartMenu";
import { useCategories } from "../../context/categoriesContext";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import { useWishlist } from "../../context/wishlistContext";
import { useCart } from "../../context/cartContext";
import categoriesRequests from "../../requests/categories";
import { Link } from "react-router-dom";
import piningicon from "../../assets/images/icons/Pining.svg";

const OccasionsMenu = () => {
    const { authenticated } = useAuth();
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(null);
    const width = useViewportWidth();
    const toggleCart = () => setIsCartOpen(!isCartOpen);
    const { wishlistQty } = useWishlist();
    const { cartQty } = useCart();
    const [modalOpen, setModalOpen] = useState(false);
    const [ocassionIsHover, setOcassionIsHover] = useState(null);
    const [simchasIsHover, setSimchasIsHover] = useState(null);
    const [selectcategories, setSelectCategories] = useState(null);
    const [parentCategory, setParentCategory] = useState(null);

    const fetchSelectedCategory = async () => {
        try {
            const categoryData = await categoriesRequests.getOcasioncategories();
            if (!categoryData.success) {
                console.error("Failed to fetch categories:", categoryData.message);
                return;
            }

            const cat = categoryData.data;
            const selected = cat.map((c) => {
                const categoryPath = c.parentCategoryName
                    ? `categories/${encodeURIComponent(c.parentCategoryName.toLowerCase())}?sub=${encodeURIComponent(
                          c.category.friendlyUrlName.toLowerCase()
                      )}`
                    : `categories/${encodeURIComponent(c.category.friendlyUrlName.toLowerCase())}`;

                return (
                    <Link
                        style={{ marginBottom: "10px", display: "block", textAlign: "start" }}
                        key={c.category._id}
                        to={categoryPath}
                    >
                        {c.category.name}
                    </Link>
                );
            });

            setSelectCategories(selected);
        } catch (error) {
            console.error("Failed to fetch selected category:", error);
        }
    };

    useEffect(() => {
        fetchSelectedCategory();
    }, []);

    return (
        <>
            <div className="links-wrap" style={{ display: "block" }}>
                {selectcategories}
            </div>
        </>
    );
};

export default OccasionsMenu;
