import React from "react";
import Portal from "./portal";
// img
import closeIcon from "../../assets/images/icons/close-white-icon.svg";
import warningIcon from "../../assets/images/icons/warning-icon.svg";
import "./style/editPurchaseModal.css";

const EditPurchaseRestrictionModal = ({ isOpen, handleClose }) => {
    const preventClose = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    };

    if (!isOpen) return null;

    return (
        <Portal>
            <div className="edit-purchase-modal-wrapper purchase-restriction-w" onClick={handleClose}>
                <div className="edit-p-modal-content-wrapper" onClick={preventClose}>
                    <div className="edit-p-modal-content">
                        <img src={warningIcon} alt="warning icon" />
                        <p>
                            Unfortunately, your 48 hour <br className="br-popup" /> editing period has expired.
                        </p>
                        <button onClick={handleClose} className="edit-purchase-modal-wrapper-button  ">
                            Ok
                        </button>
                    </div>

                    <div onClick={handleClose} className="edit-p-modal-close">
                        <img src={closeIcon} alt="close icon" />
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default EditPurchaseRestrictionModal;
