import fieldsRequests from "../requests/fields";

async function saveAutocompleteValues(canvas) {
    try {
        const canvasObjs = canvas.getObjects();
        if (!canvasObjs.length) return true;

        const searchFor = (label) => canvasObjs.filter((obj) => obj.label === label);
        // search for possible autocomplete values in canvas
        const shuls = searchFor("shul");
        const halls = searchFor("hall");
        const shulsAddresses = searchFor("shul address");
        const hallsAddresses = searchFor("hall address");
        const personNames = searchFor("person name");

        // very similar code, but I made it in this way to easy reading

        // save shuls
        for (let i = 0; i < Math.max(shuls.length, shulsAddresses.length); i++) {
            const shul = shuls[0];
            const shulAddress = shulsAddresses[0];

            if (shul?.text) {
                await fieldsRequests.saveAutocompleteValue({
                    autocompleteLabel: "shul",
                    value: shul.text,
                });
            }

            if (shulAddress?.text) {
                await fieldsRequests.saveAutocompleteValue({
                    autocompleteLabel: "shul address",
                    value: shulAddress.text,
                    referenceValue: shul?.text || "",
                });
            }
        }

        // save halls
        for (let i = 0; i < Math.max(halls.length, hallsAddresses.length); i++) {
            const hall = halls[0];
            const hallAddress = hallsAddresses[0];

            if (hall?.text) {
                await fieldsRequests.saveAutocompleteValue({
                    autocompleteLabel: "hall",
                    value: hall.text,
                });
            }

            if (hallAddress?.text) {
                await fieldsRequests.saveAutocompleteValue({
                    autocompleteLabel: "hall address",
                    value: hallAddress.text,
                    referenceValue: hall?.text || "",
                });
            }
        }

        // save person names
        for (const personName of personNames) {
            let personNameWords = (personName.text || "").split(" ");

            // save every name of full names
            for (const word of personNameWords) {
                if (word) {
                    await fieldsRequests.saveAutocompleteValue({
                        autocompleteLabel: "person name",
                        value: word,
                    });
                }
            }
        }

        return true;
    } catch (err) {
        console.error("error saving autocomplete values", err.message);
        return false;
    }
}

export default saveAutocompleteValues;
