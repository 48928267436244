/**
 * change this every time you will deploy on development or production
 */
const IS_DEVELOPMENT = true;

let API_BASE_URL,
    WP_API_BASE_URL = "",
    WP_BASE_URL = "";
//

// if (IS_DEVELOPMENT) {
//     //     // development
//     //     API_BASE_URL = "https://newhazmoonapidev.xyz/api";
//     API_BASE_URL = "http://localhost:7000/api";
// } else {
//     API_BASE_URL = "https://newhazmoonapi.xyz/api"; // production
// }

// WP_BASE_URL = "http://localhost:7000";
WP_BASE_URL = "https://newhazmoonapidev.xyz";

API_BASE_URL = "https://newhazmoonapidev.xyz/api";
// API_BASE_URL = "http://localhost:7000/api";

const CARDKNOX_IFIELD_KEY = "ifields_varddev16a000847b324f6594a12904715a3c";
const CARDKNOX_SOFTWARE_NAME = "react-cardknox-ifields";
const CARDKNOX_SOFTWARE_VAERSION = "1.0.0";

export {
    API_BASE_URL,
    WP_API_BASE_URL,
    WP_BASE_URL,
    IS_DEVELOPMENT,
    CARDKNOX_IFIELD_KEY,
    CARDKNOX_SOFTWARE_NAME,
    CARDKNOX_SOFTWARE_VAERSION,
};
