import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// requests
import cartRequests from "../../requests/cart";
// import purchasesRequests from '../../requests/purchases';
// utils
import canvasObjsToJsonStr from "../../utils/canvasObjsToJsonStr";
import { getUrlQuery } from "../../utils/url";
import postToParent from "../../utils/postToParent";
import { findDimensionsTag } from "../../utils/findDimensionsTag";
// constants
import { CANVAS_SIZE } from "../../utils/constants";
//style
import "./style.css";
import { isCustomerLogin } from "../../utils/common";
import { random } from "tinycolor2";
import { useCart } from "../../context/cartContext";
import CheckoutModal from "../Checkout/checkoutModal";

const AddToCartAndBuyNowButtons = ({ productDetails, isPreviewAvailable }) => {
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState({});
    const [cartState, setCartState] = useState({ loading: false, added: false });
    const [buyLoading, setBuyLoading] = useState({ loading: false, added: false });
    const { reloadCart, isLoading, addItemToCart, cartItems } = useCart();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setQueryParams(getUrlQuery());
        if (!cartItems || !productDetails) {
            return;
        }

        const cartItemsArray = Object.values(cartItems);
        const { product } = productDetails;
        const produ = product._id;

        const productExistsInCart = cartItemsArray.some((item) => item.wooProductId === produ);

        console.log("productExistsInCart", productExistsInCart);
        if (productExistsInCart) {
            setCartState({ added: true });
        } else {
            setCartState({ added: false });
        }
    }, [cartItems, productDetails]);
    function generateUniqueId() {
        return Math.random().toString(36).substring(2, 15) + Date.now().toString(36);
    }
    const handleSaveInCart = async (_event, buyNow = false) => {
        let cartData = await reloadCart();

        if (buyNow && cartState.added) {
            setShowModal(true);
            return;
        }
        if (buyNow) {
            setBuyLoading({ loading: true, added: true });
        } else {
            setCartState({ loading: true, added: true });
        }
        const isCustomer = isCustomerLogin();
        let customerId = null;
        let guestId = localStorage.getItem("guest_user_id");

        if (isCustomer) {
            customerId = isCustomer;
            guestId = null;
        } else if (!guestId) {
            guestId = generateUniqueId();
            localStorage.setItem("guest_user_id", guestId);
        }

        const { canvas, product, songSelected, QRText } = productDetails;
        const variation = `${songSelected ? 1 : 0}|${QRText ? 1 : 0}`;
        let variationId;

        const customCartData = {
            canvasObjects: JSON.parse(canvasObjsToJsonStr(canvas, true)),
            watermarkImage: product.filesId.pathWithWatermark,
            productId: product._id,
            productFilesId: product.filesId._id,
            productName: product.name,
            pdfSizes: product.pdfSizes || [],
            tags: product.tags || [],
            dimensions: findDimensionsTag(product.tags),

            song: null,
            QRText,
        };

        if (songSelected) {
            customCartData.song = {
                path: songSelected.path,
                name: songSelected.filename || songSelected.name,
                _id: songSelected._id,
            };
        }

        // save the current canvas size (save the canvas witdh in which it was edited)
        for (let obj of customCartData.canvasObjects) {
            obj.canvasWidth = CANVAS_SIZE;
        }

        const canvasImageBase64URL = canvas.toDataURL({
            format: "jpeg",
        });

        if (queryParams["variations[]"]) {
            const _variationId = queryParams["variations[]"].find((v) => v.includes(variation));
            if (_variationId) {
                variationId = _variationId.split("|")[0];
            }
        }
        let cartResponse;
        // console.log("variationId", customCartData);

        cartResponse = await cartRequests.saveInCart({
            woo_cart_item_key: queryParams.cart_item_key || Math.random().toString(36).substring(7),
            woo_product_id: product._id,
            product_id: product._id,
            woo_product_variation_id: variationId || "",
            customization_data: JSON.stringify(customCartData),
            customized_item_preview: canvasImageBase64URL,
            customerId,
            guestId,
            price: product.price,
            song: songSelected ? customCartData.song : null,
            cart_id: queryParams.cart_item_key || "",
        });

        // (NOTE: now doing this is wordpress when a purchase is success)
        // save cart item key for future purchase modification
        // if(cartResponse.success){
        //     await purchasesRequests.initEditCount({ wooCartItemKey: cartResponse.cart_item_key });
        // }

        let added = false;
        if (cartResponse.status === "success") {
            added = true;
        }

        // if (buyNow) setBuyLoading(false);

        // const cart_item_key = cartResponse.cart_item_key || "";
        // const wooId = product.wooProductId;
        // update cart UI (cart item on wordpress)
        // postToParent({
        //     type: "add-item-to-cart-ui",
        //     newItemData: {
        //         shopLink: `/design-your-card/?id=${wooId}`,
        //         previewLink: cartResponse.preview_url || canvasImageBase64URL,
        //         editItemLink: `/design-your-card?id=${wooId}&cart_item_key=${cart_item_key}`,
        //         hasMusic: songSelected ? "Yes" : "No",
        //         hasQR: QRText ? "Yes" : "No",
        //         name: product.name,
        //         price: product.price,
        //         cartItemKey: cart_item_key,
        //         wooProductId: wooId,
        //     },
        // });
        if (buyNow) {
            // await reloadCart();
            setBuyLoading({ loading: false, added: added });
            await reloadCart();
            setShowModal(true);
            return;
        }
        if (added) {
            // if is edit card page
            await reloadCart();
            setCartState({ loading: false, added: added });
            await Swal.fire({
                title: "Card updated",
                icon: "success",
                text: "Redireting page...",
                timer: 2000,
                showConfirmButton: false,
                showCloseButton: false,
            });

            console.log(cartResponse.data._id);

            // addItemToCart({
            //     ...cartResponse.data,
            //     productDetails: { name: product.name, price: product.price },
            // });
            navigate(`/card/${cartResponse.data.product_id}/?cart_item_key=${cartResponse.data._id}`);
        }
    };

    const handleBuy = async (e) => {
        await handleSaveInCart(e, true);
    };

    // render
    return (
        <div className="cartbtn-container">
            {queryParams.cart_item_key ? (
                <button onClick={handleSaveInCart} id="cartbtn-cart" disabled={cartState.loading || buyLoading.loading}>
                    {cartState.loading && <Spinner id="cart-loading-btn" color="dark" size="sm" type="grow" />}
                    {"Save edit"}
                </button>
            ) : (
                <button
                    onClick={handleSaveInCart}
                    id="cartbtn-cart"
                    disabled={cartState.loading || buyLoading.loading || !isPreviewAvailable || cartState.added}
                >
                    {cartState.loading && <Spinner id="cart-loading-btn" color="dark" size="sm" type="grow" />}
                    {cartState.added ? "Saved!" : "Save for later"}
                </button>
            )}
            <button onClick={handleBuy} id="cartbtn-buynow">
                {buyLoading.loading && <Spinner id="buy-loading-btn" color="dark" size="sm" type="grow" />}
                Buy Now
            </button>

            {showModal && <CheckoutModal checkout={showModal} setCheckout={setShowModal} />}
        </div>
    );
};

export default AddToCartAndBuyNowButtons;
